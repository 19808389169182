import {gql} from '@apollo/client';

import {productFragment} from './product-queries';

const dealFragment = gql`
    fragment IDeal on deal {
        dealId
        adId
        dealTitle
        dealDescription
        fuelSaverDescription
        size
        varietiesCount
        imageUri
        adPageNumber
    }
`;

const storeDealFragment = gql`
    fragment IStoreDeal on storeDeal {
        dealId
        price
        priceMultiple
        storeId
        beginDate
        endDate
    }
`;

export const getDealByDealId = gql`
    ${dealFragment}

    query getDealByDealId($dealId: ID!) {
        deal(dealId: $dealId) {
            ...IDeal
        }
    }
`;

export const getDealStoreProducts = gql`
    ${productFragment}
    query DealStoreProductsQuery(
        $dealId: ID!
        $storeId: Int!
        $page: Int = 1
        $pageSize: Int!
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        getStoreProductsByDealId(dealId: $dealId, storeId: $storeId, page: $page, pageSize: $pageSize) {
            page
            nextPage
            storeProducts {
                isAlcohol
                isWeighted
                productId
                storeProductDescriptions {
                    type
                    description
                }
                storeProductId
                departmentId
                product {
                    ...IProductFragment
                }
            }
        }
    }
`;

export const storeDealByDealIdQuery = gql`
    ${storeDealFragment}
    ${dealFragment}

    query StoreDealsByDealIdQuery($dealId: ID!, $storeId: Int!, $activeOnly: Boolean = true) {
        pageableStoreDeals: getStoreDeals(dealId: $dealId, storeId: $storeId, where: {activeOnly: $activeOnly}) {
            page
            nextPage
            storeDeals {
                ...IStoreDeal
            }
        }
        deal(dealId: $dealId) {
            ...IDeal
        }
    }
`;

export const storeDealsByDealIdsQuery = gql`
    ${storeDealFragment}
    ${dealFragment}

    query StoreDealsByDealIdsQuery($dealIds: [ID!], $storeId: Int!, $activeOnly: Boolean = true) {
        pageableStoreDeals: getStoreDeals(whereIds: $dealIds, storeId: $storeId, where: {activeOnly: $activeOnly}) {
            page
            nextPage
            storeDeals {
                ...IStoreDeal
            }
        }
        dealsByIds(dealIds: $dealIds) {
            deals {
                ...IDeal
            }
        }
    }
`;

export const getStoreDealsBySearchQuery = gql`
    ${storeDealFragment}
    ${dealFragment}

    query StoreDealsBySearchQuery($storeId: Int!, $where: storeDealsInput) {
        pageableStoreDeals: getStoreDeals(storeId: $storeId, where: $where, pageSize: 4) {
            page
            nextPage
            storeDeals {
                ...IStoreDeal
                deal {
                    ...IDeal
                }
            }
        }
    }
`;
