import {FC, PropsWithChildren} from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import Spinner from '@hy-vee/web-core/lib/components/loading-spinner';

import {getDealByDealId} from 'autogen/getDealByDealId';
import {getListsQuery, list as List, createListMutation} from 'codegen/graphql';
import {reportListEvent} from 'client/services/analytics-service';
import {getProductNameByProductId} from 'autogen/getProductNameByProductId';
import {setAddToListModalError} from 'client/action-creators/lists/set-add-to-list-modal-error';
import {createList, createListItem, deleteListItemByProductOrDeal} from 'client/services/lists-service';
import {
    submitProdxAddToList,
    submitProdxRemoveFromList
} from 'client/graphql/mutations/mutation-actions/prodx-shelf-mutations-actions';
import {GetItemByItemId_itemById} from 'autogen/GetItemByItemId';

import AddToListsModalBody from './add-to-lists-modal-body';

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
`;

const SpinnerContainer = styled.div`
    display: inline-flex;
    height: 70px;
    position: relative;
    width: 70px;
`;

const determineInitiallySelectedListIds = (lists: List[], productId?: string, dealId?: string, itemId?: string) =>
    (lists || []).reduce((selectedListIds, list) => {
        const hasItemInList = list.listItems?.find((listItem) => {
            if (productId) {
                return listItem.productId === Number(productId);
            } else if (dealId) {
                return listItem.dealId === Number(dealId);
            }

            return listItem.itemId === itemId;
        });

        if (hasItemInList) {
            return [...selectedListIds, list.listId];
        }

        return selectedListIds;
    }, []);

interface IFormProps {
    aisleId?: string;
    closeModal: Function;
    dealData?: getDealByDealId;
    itemData?: GetItemByItemId_itemById | null;
    listData?: getListsQuery;
    loading: boolean;
    productData?: getProductNameByProductId;
    responseId?: string | null;
    responseProductId?: string | null;
}

const AddProductOrDealToListForm: FC<PropsWithChildren<PropsWithChildren<IFormProps>>> = ({
    aisleId,
    closeModal,
    dealData,
    itemData,
    loading,
    listData,
    productData,
    responseId,
    responseProductId
}) => {
    const addToListModalError = useSelector((state: any) => state.lists.addToListModalError);
    const dispatch = useDispatch();
    const setErrorMessage = (error) => dispatch(setAddToListModalError(error));

    if (loading) {
        return (
            <LoadingContainer>
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            </LoadingContainer>
        );
    }

    const currentLists: List[] = (listData?.lists as List[]) || [];

    if (!productData && !dealData && !itemData) {
        return null;
    }

    const productId = productData?.product?.productId;
    const dealId = dealData?.deal?.dealId;
    const itemId = itemData?.itemId;
    const initialListIds = determineInitiallySelectedListIds(currentLists, productId, dealId, itemId);
    const copyOfInitialListIds = [...initialListIds];

    return (
        <Formik
            initialValues={{
                listIds: initialListIds,
                name: ''
            }}
            onSubmit={async (values, formikActions) => {
                let addedIds: string[] = [],
                    removedIds = copyOfInitialListIds;

                if (values.name) {
                    const response: {data: createListMutation} = await createList(values, setErrorMessage);

                    if (!response?.data.createList) {
                        formikActions.setSubmitting(false);

                        return;
                    }

                    addedIds.push(response.data.createList.listId);
                    currentLists.push(response.data.createList as List);
                    reportListEvent('Create List');
                }

                if (values.listIds) {
                    addedIds = addedIds.concat(values.listIds.filter((id) => !copyOfInitialListIds.includes(id)));
                    removedIds = copyOfInitialListIds.filter((id: never) => !values.listIds.includes(id));
                }

                await Promise.all([
                    ...addedIds.map((listId) => {
                        const listFound = currentLists.find((list) => list.listId === listId);

                        if (listFound) {
                            const text =
                                productData?.product?.name || dealData?.deal?.dealTitle || itemData?.description || '';
                            const itemDataToUpdate = {
                                dealId: dealId ? Number(dealId) : null,
                                itemId: itemId ?? null,
                                listId,
                                productId: productId ? Number(productId) : null,
                                text
                            };

                            reportListEvent(`Add ${dealId ? 'Deal ' : ''}to List`);

                            submitProdxAddToList({
                                aisleId,
                                responseId,
                                responseProductId
                            });

                            return createListItem(listFound.listItems, itemDataToUpdate);
                        }

                        return Promise.resolve();
                    }),
                    ...removedIds.map((listId) => {
                        reportListEvent('Remove from List');

                        submitProdxRemoveFromList({
                            aisleId,
                            responseId,
                            responseProductId
                        });

                        return deleteListItemByProductOrDeal(listId, listData?.lists, productId, dealId, itemId);
                    })
                ]);

                closeModal();
                formikActions.setSubmitting(false);
            }}
        >
            {(formikProps) => (
                <AddToListsModalBody
                    closeModal={closeModal}
                    errorMessage={addToListModalError}
                    formikProps={formikProps}
                    itemDescription={dealId ? 'deal' : 'item'}
                    listData={listData}
                    setErrorMessage={setErrorMessage}
                />
            )}
        </Formik>
    );
};

export default AddProductOrDealToListForm;
